.App {
  align-items: center;
  justify-content: center;
  display: flex;
  color: navy;
}

.Body {
  width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Calendar {
  width: 600px;
  border: navy dotted 1px;
}

.Column {
  width: 200px;
  height: 500px;
  padding-bottom: 50px;
  justify-content: space-evenly; 
  align-items: center;
  display: flex;
  flex-direction: column;
}

.CalDate {
  width: 80px;
  height: 80px;
  margin: 10px;
}

.PastDate {
  cursor: pointer;
  color: navy;
}

.PastDate:hover {
  text-decoration: underline;
}

.FutureDate {
  cursor: not-allowed;
  color: gray;
}

.Center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Comment {
  color: white;
  width: 468px;
}

.About {
  width: 558px;
  border: navy dotted 1px;
  padding: 20px;
}

.AboutDesc {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

.ToolBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.Links {
  display: flex;
  flex-direction: row;
}